<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <div v-if="fetchup">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <base-material-card
      v-else
      color="primary"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-row class="align-content-md-space-between">
            <v-col
              cols="12"
              md="9"
              sm="6"
            >
              <div class="text-h3 font-weight-light">
                <v-btn
                  class="mr-2"
                  outlined
                  icon
                  small
                  color="primary"
                  @click="fetchLogs"
                >
                  <v-icon small>
                    mdi-refresh
                  </v-icon>
                </v-btn>
                Access List <i class="text-caption">Total: <b>{{ total }}</b></i>
              </div>

              <div class="text-subtitle-1 font-weight-light">
                View all attempted accesses
              </div>
            </v-col>
          </v-row>
        </div>
      </template>

      <v-data-table
        :headers="headers"
        :items="clients"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.date="{ item }">
          {{ moment(item.date).format('MMM Do YYYY, h:mm a') }}
          || <b class="green--text text--darken-2">{{ moment(item.date).fromNow() }}</b>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="item.status?'green':'red'"
            outlined
            dark
          >
            <v-icon
              v-if="item.status"
              left
              class="green--text"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else
              left
              class="red--text"
            >
              mdi-close-circle
            </v-icon>
            {{ item.status?'Granted':'Failed' }}
          </v-chip>
        </template>
        <template v-slot:no-data>
          <v-btn
            text
            color="primary--text"
            @click="fetchLogs"
          >
            Refresh
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
    <!--    Base Material End-->
  </v-container>
</template>
<script>
  const axios = require('axios')
  export default {
    name: 'AccessList',
    data: () => ({
      total: null,
      search: null,
      fetchup: false,
      menu: false,
      err: [],
      dialog: false,
      dialogDelete: false,
      subscribeDialog: false,
      sub: {
        package: { _id: '', name: '', price: '' },
        startDate: null,
        endDate: null,
        receiptNo: null,
      },
      headers: [
        {
          text: 'Client Name',
          align: 'start',
          sortable: false,
          value: 'lastName',
        },
        { text: 'Membership', value: 'lastName' },
        { text: 'Status', value: 'status' },
        { text: 'Date', value: 'date' },
        // { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      clients: [],
      editedIndex: -1,
      editedItem: {
        firstName: '',
        lastName: '',
        password: 'client1212',
        confirmPassword: 'client1212',
        phone: '',
        email: '',
        dob: '',
        address: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
      },
      defaultItem: {
        firstName: '',
        lastName: '',
        phone: '',
        password: 'client1212',
        confirmPassword: 'client1212',
        email: '',
        dob: '',
        address: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Register New Client' : 'Edit Client'
      },
    },

    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.fetchLogs()
    },

    mounted () {
      // const self = this
      //
      // setInterval(() => {
      //   self.fetchLogs()
      // }, 2000)
    },

    methods: {
      viewSubDetails (clientData) {
        this.editedItem = clientData
        this.subscribeDialog = true
        this.$store.dispatch('fetchPackages')
      },
      fetchLogs () {
        const currLogs = localStorage.getItem('checkinLogs')
        this.fetchup = true
        const self = this
        axios.get(this.apiSet.checkinLogs)
          .then(response => {
            const obj = response.data.logs
            if (obj.length > currLogs) {
              self.soundMsg()
              self.$store.commit('setSnack', { t: 'New Checkin Logged.', c: 'green darken-3' })
              localStorage.setItem('checkinLogs', obj.length)
            }
            const checkinLogs = []
            for (let key = 0, len = obj.length; key < len; key++) {
              checkinLogs.push({
                id: obj[key]._id,
                // fullname:obj[key].user.firstName+' '+obj[key].user.lastName,
                lastName: obj[key].user?.lastName,
                email: obj[key].user?.email,
                phone: obj[key].user?.phone,
                status: obj[key].status,
                date: obj[key].date,
                expiry: obj[key].subscription?.endDate,
              })
            }
            self.clients = checkinLogs
            self.total = obj.length
            self.fetchup = false
            // self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
          }).catch(
            e => {
              self.fetchup = false
              self.err.push(e)
            },
          )
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.$refs.observer.reset()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeSubDialog () {
        this.subscribeDialog = false
        this.sub.package = { _id: '', name: '', price: '' }
      },
    },
  }
</script>
<style scoped>
.pulse {
  cursor: pointer;
  border-radius:50px;
  animation: pulse 1s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
    box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
    box-shadow: 0 0 0 10px rgba(22, 160, 133, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
    box-shadow: 0 0 0 0 rgba(22, 160, 133, 0);
  }
}
</style>
